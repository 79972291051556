import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/lib/ink-stains/post-layout/post-layout.tsx";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`The ceiling won’t stop me from going up there,`}<br parentName="p"></br>{`
`}{`Knife and blood are the price I pay.`}<br parentName="p"></br>{`
`}{`The sins will repay. I swear.`}<br parentName="p"></br>{`
`}{`the knife in my bone is there to stay.`}</p>
    <p>{`Now in the face of God, I must repent,`}<br parentName="p"></br>{`
`}{`For men I killed and orphans I left.`}<br parentName="p"></br>{`
`}{`Shall the sky’s punishment be imminent?`}<br parentName="p"></br>{`
`}{`Forever in my spirit there is a cleft.`}</p>
    <p>{`But, how could I have rest,`}<br parentName="p"></br>{`
`}{`When a sinful soul is walking in this place.`}<br parentName="p"></br>{`
`}{`A father deep in the ground with knife in his chest,`}<br parentName="p"></br>{`
`}{`And a fatherless daughter smiling beside his grave with his blood on her face.`}</p>
    <p>{`So long as God has power, or fish can swim,`}<br parentName="p"></br>{`
`}{`So long my shadow will hang over her and her smile will always be grim.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      